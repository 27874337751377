// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-acupression-js": () => import("./../../../src/pages/acupression.js" /* webpackChunkName: "component---src-pages-acupression-js" */),
  "component---src-pages-hypnose-js": () => import("./../../../src/pages/hypnose.js" /* webpackChunkName: "component---src-pages-hypnose-js" */),
  "component---src-pages-hypnose-therapeutique-js": () => import("./../../../src/pages/hypnoseTherapeutique.js" /* webpackChunkName: "component---src-pages-hypnose-therapeutique-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

